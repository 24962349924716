import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';
import axios from 'axios';
import styled from 'styled-components';
import Seo from '../components/seo';
import InfoSection from '../components/infoSection/InfoSection';
import AboutUs from '../components/aboutUs/AboutUs';
import ProviderSection from '../components/providerSection/ProviderSection';
import ProviderSectionRider from '../components/providerSection/ProviderSectionRider';
import ProviderSectionPharm from '../components/providerSection/ProviderSectionPharm';
import { providerDetailsPharmacy } from '../components/providerSection/providerDataPharmacy';
import { providerDetailsRider } from '../components/providerSection/providerDataRider';
import { providerDetails } from '../components/providerSection/providerData';
import WorkFlowSection from '../sections/service-section/workflowIndex';
import BenefitsSection from '../sections/service-section/MtmBenefitsIndex';
import MtmWhyNow from '../sections/service-section/MtmWhyNowIndex';
import AdCard from '../sections/service-section/AdCard';
import MtmWhyUs from '../sections/service-section/MtmWhyUsIndex';
import Partners from '../components/partners/Partners';
import Footer from '../components/homeNav/footer/Footer';
import HomeNav from '../components/homeNav/HomeNav';
import SideBar from '../components/sideBar/SideBar';
import BotNav from '../components/bottomNav';
import useAuthState from '../stores/auth';

export default function Afyabook({ location }) {
  const { logIn, getUserId } = useAuthState();
  const [isOpen, serIsOpen] = useState(false);
  const toggle = () => {
    serIsOpen(!isOpen);
  };
  const [token] = useQueryParam('token', StringParam);
  const [authToken] = useQueryParam('auth_token', StringParam);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const userId = getUserId()
  const isBrowser = typeof window !== 'undefined'

  const verifyGoogleToken = async (e) => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/verify-login-token/${token}`,
      );
      logIn(result.data);
      navigate('/pharmacy');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const { message } = error.response.data;
      setError(message);
    }
  };

  const verifyEmailToken = async (e) => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/verify-email/${authToken}`,
      );
      logIn(result.data);
      navigate('/pharmacy');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const { message } = error.response.data;
      setError(message);
    }
  };
  useEffect(() => {
    if (token) {
      verifyGoogleToken();
    }
    if (authToken) {
      verifyEmailToken();
    }
  }, []);

  console.log("userId", userId)

  //if (userId) { isBrowser && window.OneSignal.login(userId) };

  // let OneSignal = window.OneSignal || [];
  // OneSignal.push(function () {
  //   OneSignal.init({ appId: "b669d6d5-4806-4fd0-9b52-5d58d57fb710" });
  //   OneSignal.getUserId(function (userId) {
  //     console.log("OneSignal User ID:", userId);
  //     OneSignal.login(userId); // Replace with your actual user ID
  //   });
  // });

  return (
    <>
      <Seo
        title="Find medication and doctors in Kenya | Afyabook - Healthcare at your fingertip"
        description="Discover and connect directly to pharmacies and healthcare providers. Manage your healthcare information and leverage your data for better health. Learn more..."
        keywords="Online Pharmacy,Online Pharmacies in kenya,Online Pharmacy in kenya, Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain, retail pharmacies in kenya,retail pharmacies in Nairobi, find doctors in kenya, telemedicine,chemist in Kenya, pharmacy,medication therapy management, remote patient monitoring, selfcare, goodlife"
        img="https://www.afyabook.com/static/8f0d82e8a3e5523628b47cab0223c9f3/19bc3/indexhero.webp"
        siteUrl="https://www.afyabook.com"
        canonical="https://www.afyabook.com"
      />
      <>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <HomeNav toggle={toggle} />
      </>
      <InfoSection />
      <AboutUs />
      {/* <WorkFlowSection /> */}
      <BenefitsSection />
      {/* <MtmWhyNow /> */}
      <AdCard />
      <MtmWhyUs />
      <ProviderSectionPharm {...providerDetailsPharmacy} />
      <ProviderSection {...providerDetails} />
      <ProviderSectionRider {...providerDetailsRider} />
      <Partners />
      <BotNav />
      <Footer />
    </>
  );
}

// export const NavMobile = styled.div`
//   display: none;
//   @media screen and (max-width: 767px) {
//     display: flex;
//   }
// `;
