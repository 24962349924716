import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import ServiceCard from '../serviceCard/ServiceCard';

function InfoSection() {
  return (
    <>
      <HeroContainer>
        <HeroContent>
          <HeroItems>
            <HeroH1>What Matters to You?</HeroH1>
            <HeroP>Need medication or consultation with a doctor near your location?</HeroP>
            <HeroP>Afyabook is ready for you anytime</HeroP>
            <Buttons>
              <Button to="/pharmacy">Browse Products</Button>
              <Button to="/upload-prescription">Upload prescription</Button>
            </Buttons>
          </HeroItems>
          <HeroImage><StaticImage src="../../images/indexhero.webp" alt="Afyabook online doctor" placeholder="blurred" /></HeroImage>
        </HeroContent>
      </HeroContainer>
      <HeroContainerServ>
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <ImageDiv >
            <img src="https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_700,f_auto/v1670403491/afyabook%20images/christmas_sale_15_off_q8mn9y.jpg" width="100%" height="100%" />
          </ImageDiv>
          <ImageDivM >
            <img src="https://res.cloudinary.com/tripleaim-software/image/upload/c_scale,w_320,f_auto/v1670403491/afyabook%20images/christmas_sale_15_off_q8mn9y.jpg" width="100%" height="100%" />
          </ImageDivM>
        </div> */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h2>Services</h2>
        </div>
        <HeroContentService>
          <ServiceCard />
        </HeroContentService>
      </HeroContainerServ>
    </>
  );
}
export default InfoSection;

const HeroContainer = styled.div`
    height: 100%;
    width: 100%;
    margin:0px;
    padding:0px;
    z-index:0;
    min-height: 50vh;
    
`;

const HeroContainerServ = styled.div`
    height: 100%;
    width: 100%;
    margin-top:3px;
    padding:3px;
    background: white;
`;

const HeroContent = styled.div`
    display:flex;
    height: 100%;
    width:100%;
    //margin-top: 3rem;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap-reverse;  
    
`;

const HeroContentService = styled.div`
    display:flex;
    height: 100%;
    width:100%;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;  
    padding:1em;
    background: white;      
`;
const HeroImage = styled.div`
   max-width: 450px;
   min-width: 200px;
`;
const HeroItems = styled.div`
    display: block;
    text-align: center;
    height: 100%;
    padding: 1rem;
    width:30em;
    color:#000;
`;
const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    color:#000;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
`;
const Button = styled(Link)`
    background: #71C4DE;
    white-space: nowrap;
    padding: 10px;
    margin: 2px;
    color: white;
    font-size:  20px;
    outline: none;
    border: 2px solid #71C4DE;
    cursor: pointer;
    text-decoration: none;
    transitions: 0.3s !important;
    border-radius: 10px;
    margin-bottom: 1rem;


    &:hover {
        text-decoration-line: none;        
    }
`;

const HeroH1 = styled.h1`
    font-size: clamp(1.2rem,2vw, 2.5rem);
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
`;

const HeroP = styled.p`
    font-size: clamp(1rem,2vw, 1.5rem); 
    padding: 0 0.5rem;   
`;

const ImageDiv = styled.div`
    display:none;
    
    @media screen and (min-width: 768px) {
      display:block;
      width: 700px;
      height: 220px;
    }
`;
const ImageDivM = styled.div`
    display:block;
    width: 350px;
    height: 150px;
    
    @media screen and (min-width: 768px) {
      display:none;
    }
`;
