import React from "react"
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  RegBoxin,
  Counter,
  CartLink,
  AfyabookLogo,
} from "./NavBarElement"
import Logo from "../../images/Afyabook.svg"
import { FaBars } from "react-icons/fa"
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined"
import SearchElement from "./searchElement"
import useAuthState from "../../stores/auth"
import useCartState from "../../stores/cart"

const Navbar = ({ toggle }) => {
  const { isLoggedIn } = useAuthState()

  const { getCartLength } = useCartState()

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <AfyabookLogo src={Logo} />
          </NavLogo>
          {isLoggedIn() && (
            <RegBoxin>
              <CartLink to="/Cart">
                <ShoppingBagOutlinedIcon
                  style={{ fontSize: "2.1rem", marginRight: "5px" }}
                />
                <Counter>{getCartLength()}</Counter>
              </CartLink>
            </RegBoxin>
          )}
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
        </NavbarContainer>
      </Nav>
      <div style={{ position: "sticky", top: "60px", zIndex: "20" }}>
        <SearchElement />
      </div>
    </>
  )
}
export default Navbar
