import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const AboutContainer = styled.div`
  padding: 50px;
  width: 100%;
  text-align:center;
  @media screen and (min-width: 320px) {
    padding: 10px;
  }
`
const AboutWrapper = styled.div`
  display: block;
  
  }
`
const Header = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;

  color: #171717;
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`
const Text = styled.p`
  font-family: Oswald;
  font-weight: 200;
  font-size: 1.5em;
  
`
const LearnMoreBtn = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  height: 30px;
  width: 130px;

  text-align: center;
  margin: 10px 20px;
  text-decoration: none;
  color: #1d9682;
  border-radius: 8px;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: #2e4158;
    text-decoration: underline;
  }
`
export default function AboutUs() {
  return (
    <AboutContainer id="about">
      <AboutWrapper>
        <Header>About Us</Header>
        <Text>
            Afyabook is an online pharmaceutical marketplace platform that
            facilitates the marketing and provision of medicines and healthcare
            services in Kenya. <br />
            It connects healthcare providers and patients on a single platform
            to facilitate goodlife, Health and Well-being".
            <br /> Discover and connect directly to pharmacies and other
            providers in Africa. Manage your healthcare information and leverage
            your data for better health.
        </Text>
        <LearnMoreBtn
          to="/aboutUs"
        >
          Learn More
        </LearnMoreBtn>
      </AboutWrapper>
    </AboutContainer>
  )
}
