import React from "react"
import {
  ImgCont,
  WhyContainer,
  WrapperAd,
} from "../provider-section/styles/whyNow"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function AdCard() {
  return (
    <WhyContainer>
      <WrapperAd >
        <Link to="/userprofile">
        <div style={{margin:"10px"}}>
          <StaticImage height={500} src="https://res.cloudinary.com/tripleaim-software/image/upload/v1690319466/Record_and_monitor_your_measurements_here_1_mgbql5.jpg" alt="remote patient monitoring" placeholder="blurred" imgStyle={{ borderRadius: "20px" }} />
        </div>
        </Link>
        <Link to="/upload-prescription">
        <div style={{margin:"10px"}}>
          <StaticImage height={500} src="https://res.cloudinary.com/tripleaim-software/image/upload/v1690319480/upload_prescription_1_ad5qex.jpg" alt="Upload prescription" placeholder="blurred" imgStyle={{ borderRadius: "20px" }}/>
        </div>    
        </Link>
      </WrapperAd>
    </WhyContainer>
  )
}
